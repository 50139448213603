import React, { useRef, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, NavLink} from 'react-router-dom';
import Main from './Main'
import About from './About'
import './App.css';
//import { solid, regular, brands } from '@fortawesome/fontawesome-svg-core/import.macro' // <-- import styles to be used
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type Props = {
  text: string,
  href: string
}

const A = (props: Props) => {
  return (
    <NavLink className='h-20 text-white bg-slate-500 w-full flex items-center justify-center text-center text-2xl hover:opacity-80 hover:text-purple-300' to={props.href}>
      {props.text}
    </NavLink>
  )
}

function App() {


  

  return (
    <Router>
    <div className='flex'>
      <div className="w-3/3">
        <Routes>
              <Route path="/" element={<Main />}/>
              <Route path="/about" element={<About />}/>
              <Route path="*" element={<Navigate replace to="/" />} />
        </Routes>
        </div>
    </div>
    </Router>
  );
}

export default App;
