import React, { useEffect, useRef } from "react";
import gsap from "gsap";
import Socials from "./Socials";
import { NavLink } from "react-router-dom";

export default function Main() {
	const al = useRef(null);
	const tl = useRef(gsap.timeline());
	const q = gsap.utils.selector(al);

	const scaleUp = ({ currentTarget }: any) => {
		gsap.to(currentTarget, { scale: 1.1 });
	};

	const scaleDown = ({ currentTarget }: any) => {
		gsap.to(currentTarget, { scale: 1 });
	};

	useEffect(() => {
		tl.current = gsap
			.timeline()
			.fromTo(q(".lo"), { height: 0 }, { height: "100vh", duration: 1 })
			.fromTo(
				q(".los"),
				{ opacity: 0 },
				{ opacity: 1, duration: 2, delay: 0.5 }
			);
	}, [q]);
	return (
		<div
			className="bg-slate-700 h-full min-h-screen flex items-center justify-center"
			ref={al}>
			<div className="lo bg-slate-800 p-5 space-x-10 h-full  flex items-center justify-center w-screen">
				<div className="los">
					<div className="logo w-auto h-auto">
						<img
							className="ge w-60 rounded-lg border-5 mx-auto border-4 border-slate-900"
							src="devrim.png"
							alt=""
							onMouseEnter={scaleUp}
							onMouseLeave={scaleDown}
						/>
						<h1
							className="ge text-white text-5xl md:text-6xl mt-5"
							onMouseEnter={scaleUp}
							onMouseLeave={scaleDown}>
							Devrim Ayavefe
						</h1>
						<div className="flex mt-10 items-center justify-center">
							<Socials />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
