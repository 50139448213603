import React, { useEffect, useRef } from 'react'
import gsap from 'gsap'
import {NavLink} from 'react-router-dom'


export default function About() {

  return (
    <div className="bg-white h-full min-h-screen flex items-center justify-center">
    test
  </div>
  )
}