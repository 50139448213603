
import { SocialIcon } from 'react-social-icons';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { gsap } from "gsap"
import { useEffect, useRef } from 'react';



type Props = {
    network: string
    color: string
    id: string
}

function Icon(props: Props) {
    const onMouseEnter = ({currentTarget} : any) => {
        gsap.to(currentTarget, {scale:1.2})
      }
    
      const onMouseLeave = ({currentTarget} : any) => {
        gsap.to(currentTarget, {scale:1})
      }    
  return (
<div onClick={() => {
toast.clearWaitingQueue(); toast.dismiss();     toast(props.id, {
  onClick: () => {toast.clearWaitingQueue(); toast.dismiss();     navigator.clipboard.writeText(props.id); toast("Copied!")}
});
}}>
    <SocialIcon className={"hover:bg-cyan-50 bg-white border-2 border-"+props.color+"-500 rounded-full hover:opacity-80 eg"} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} network={props.network} />
</div>
  )
}

function Socials() {

  const la = useRef(null)
  const q = gsap.utils.selector(la)
  useEffect(() => {
    gsap.to(q(".eg"), {y:5, repeat:-1, yoyo:true, yoyoEase:true, ease:"back", duration:1})
    gsap.to(q(".eg"), {scale:1.1, repeat:-1, yoyo:true, yoyoEase:true, ease:"back", duration:1})
  }, [])



  return (
      
    <div className='text-center text-black dark:text-white flex flex-col align-middle justify-center space-x-5' ref={la}>
<ToastContainer className="text-center text-black text-lg"
position="bottom-center"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
limit={1}
pauseOnFocusLoss
draggable
pauseOnHover
/>
      <div className="flex items-center justify-center text-center space-x-5">


  <Icon network="snapchat" color="yellow" id="dvrm.efe" />
  <Icon network="instagram" color="purple" id="@devrimayavefe" />
  <Icon network="email" color="stone" id="atacan@devrimayavefe.com" />
  </div>

</div>

  );
}

export default Socials;